
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Wave from './Components/Wave';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import DrawHome from './Components/DrawHome';
import ShowUserField from './Components/ShowUserField';
import {RecoilRoot, useRecoilState} from 'recoil';
import { textState } from './Components/PageAtom';
const App=()=> {

  const svgString = encodeURIComponent(renderToStaticMarkup(<Wave />));
  document.body.style.background = `url("data:image/svg+xml,${svgString}") top / auto repeat-x, #f5f5f5`;
 
  return (
    <RecoilRoot>
   <Router>
     <Switch>
     <Route exact path="/">
     <ShowUserField/>
    </Route>
    <Route path="/:username"render={(props) => (
    <DrawHome username={props.match.params.username}/>
)} />
  </Switch>
  
   </Router>
   </RecoilRoot>
  );
}

export default App;
