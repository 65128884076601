import { useEffect, useState } from "react";
import axios from 'axios';
import { Sugar } from 'react-preloaders2';
import Profile from './Profile';
import { useRecoilState } from "recoil";
import { dataGot, error, IsLoading, textState } from "./PageAtom";
import Followers from "./Followers";
import { useHistory } from "react-router-dom";
interface urlParams {
    username: string;
}
const DrawHome = (props:urlParams) => {
    const history = useHistory();
    const [dataSet, setDataSet] = useRecoilState(dataGot);
    let [errorSet, setShowError] =useRecoilState(error);
    const [isLoading, setIsLoading] = useRecoilState(IsLoading);
    useEffect(()=>{
        let headers = {
            Authorization:"Basic a2FyaW1ra2Fuamk6Z2hwX1ZxUlpRSHAyY3l3eGF1bXZ5RTlhRGppQzBZeU9LNTFpbEJCdQ=="
        }
        axios.get('https://api.github.com/users/'+props.username,{ headers }).then(response => {
                      setDataSet(response.data);
                      setIsLoading(false);
                    })
                        .catch((error) => {setShowError("d-block"); history.push("/"); });
                        
    },[props.username])

    return (<><Profile data={dataSet} username={props.username}/>
    <Followers login={dataSet}/>
    <div className="container text-center" style={{ marginTop: 60 }}>
                <figure>
                    <blockquote className="blockquote">
                        <p className="mb-0" style={{ display: "none" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                            posuere erat a ante.
                        </p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                        Made with ❤️ by&nbsp;<a href="#">Cryosoft Corporation</a>&nbsp;
                    </figcaption>
                </figure>
            </div>
    </>)
}
export default DrawHome;