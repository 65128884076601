




interface DataProps {
    data: {},
    username: string,
}
const Profile = (props: DataProps) => {

    let data: any = props.data;
    return (
        <>
            <div className="container" style={{ marginTop: 70 }}>
                <div className="text-center">
                    <img
                        className="rounded-circle"
                        src={data.avatar_url}
                        loading="lazy"
                        height="150px"
                        style={{ boxShadow: "0px 4px 20px 0px rgb(0,0,0)" }}
                    />
                    <h1 style={{ marginTop: 10 }}>
                        {data.name}
                        <br />
                    </h1>
                    <h5 style={{ marginTop: 10 }}>@{data.login}</h5>
                </div>
                <div className="row">
                    <div className="col-lg-4" style={{ marginTop: 10 }}>
                        <div
                            className="card"
                            style={{
                                boxShadow:
                                    "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)"
                            }}
                        >
                            <div className="card-body">
                                <h4 className="card-title">Quick Statistics</h4>
                                <p className="card-text">
                                    Public Repositories
                                    <span className="badge rounded-pill bg-primary float-end">
                                        {data.public_repos}
                                    </span>
                                </p>
                                <p className="card-text">
                                    Public Gists
                                    <span className="badge rounded-pill bg-info float-end">
                                        {data.public_gists}
                                    </span>
                                </p>
                                <p className="card-text">
                                    Followers:
                                    <span className="badge rounded-pill bg-success float-end">
                                        {data.followers}
                                    </span>
                                </p>
                                <p className="card-text">
                                    Following:
                                    <span className="badge rounded-pill bg-warning float-end">
                                        {data.following}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{ marginTop: 10 }}>
                        <div
                            className="card"
                            style={{
                                boxShadow:
                                    "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)"
                            }}
                        >
                            <div className="card-body">
                                <h4 className="card-title">Bio</h4>
                                <h6 className="text-muted card-subtitle mb-2">
                                    About {data.name}
                                </h6>
                                <p className="card-text">
                                    <b>{data.bio?data.bio:<span className="text-danger">Bio Not available</span>}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{ marginTop: 10 }}>
                        <div
                            className="card"
                            style={{
                                boxShadow:
                                    "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)"
                            }}
                        >
                            <div className="card-body">
                                <h4 className="card-title">More Info</h4>
                                <p className="card-text">Location: <b>{data.location?data.location:<span className="text-danger">N/A</span>}</b></p>
                                <p className="card-text">Company: <b>{data.company?<a href={"https://github.com/"+data.company.substring(1)}>{data.company}</a>:<span className="text-danger">N/A</span>}</b></p>
                                <p className="card-text">Site: <b>{data.blog?<a href={data.blog}>{data.blog}</a>:<span className="text-danger">N/A</span>}</b></p>
                                <p className="card-text">Twitter: <b>{data.twitter_username?<a href={"https://twitter.com/"+data.twitter_username}>{data.twitter_username}</a>:<span className="text-danger">N/A</span>}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
    )
}

export default Profile;