import randomColor from "randomcolor";
const Wave = (() => {
    let random = randomColor();
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill={random}
            fillOpacity={1}
            d="M0,160L26.7,160C53.3,160,107,160,160,144C213.3,128,267,96,320,122.7C373.3,149,427,235,480,234.7C533.3,235,587,149,640,128C693.3,107,747,149,800,165.3C853.3,181,907,171,960,186.7C1013.3,203,1067,245,1120,266.7C1173.3,288,1227,288,1280,266.7C1333.3,245,1387,203,1413,181.3L1440,160L1440,0L1413.3,0C1386.7,0,1333,0,1280,0C1226.7,0,1173,0,1120,0C1066.7,0,1013,0,960,0C906.7,0,853,0,800,0C746.7,0,693,0,640,0C586.7,0,533,0,480,0C426.7,0,373,0,320,0C266.7,0,213,0,160,0C106.7,0,53,0,27,0L0,0Z"
          />
        </svg>
      )
});
export default Wave;
