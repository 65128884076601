import { atom } from "recoil";

export const textState = atom({
    key: 'pageSet', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
  });
  export const IsLoading = atom({
    key: 'isLoading', // unique ID (with respect to other atoms/selectors)
    default: true, // default value (aka initial value)
  });
  export const dataGot = atom({
    key: 'dataSet', // unique ID (with respect to other atoms/selectors)
    default: {}, // default value (aka initial value)
  });
  export const error = atom({
    key: 'errorSet', // unique ID (with respect to other atoms/selectors)
    default: "d-none", // default value (aka initial value)
  });