import { useRecoilState } from "recoil";
import { dataGot, IsLoading } from "./PageAtom";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useHistory} from "react-router-dom";

interface DataProps {
    login:{}
}

const Followers =(props:DataProps)=>{
    const [loadFollowers, setLoadFollowers] = useState(true);
    const [followers, setFollowers] = useState([]);
    const [loadFollowing, setLoadFollowing] = useState(true);
    const [following, setFollowing] = useState([]);
    const [isLoading, setIsLoading] = useRecoilState(IsLoading);
    const [dataSet, setDataSet] = useRecoilState(dataGot);
    let history = useHistory();
    useEffect(() => {
        let username:any = dataSet;
        let headers = {
            Authorization:"Basic a2FyaW1ra2Fuamk6Z2hwX1ZxUlpRSHAyY3l3eGF1bXZ5RTlhRGppQzBZeU9LNTFpbEJCdQ=="
        }

            axios.get("https://api.github.com/users/"+username.login+"/followers?per_page=100", { headers }).then(response => {
                setFollowers(response.data);
                setLoadFollowers(false)
                
            })
                .catch((error) => { console.error(error); });
       
            axios.get("https://api.github.com/users/"+username.login+"/following?per_page=100", { headers }).then(response => {
                setFollowing(response.data);
                setLoadFollowing(false)            
            })
                .catch((error) => { console.error(error); });
       
        return () => {
            setLoadFollowers(false)
            setLoadFollowing(false)
        }
    },[dataSet]);
    return (<div className="container">
    <div className="row" style={{ marginTop: 20 }}>
        <div className="col-lg-6" style={{ marginTop: 10 }}>
            <div
                className="card"
                style={{
                    boxShadow:
                        "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)"
                }}
            >
                <div className="card-body">
                    <h4 className="card-title">Following</h4>
                    <h6 className="text-muted card-subtitle mb-2">
                       Showing only the first 100 Following
                    </h6>
                    {loadFollowing ? "" : following.map((res: any, key: number) => {
                        return  <span onClick={()=>history.push("/"+res.login)} key={"following-"+key}><img
                            
                            className="rounded-circle"
                            loading="lazy"
                            src={res.avatar_url}
                            height="50px"
                            width="50px"
                            style={{ margin: 5 }}
                        />
                        </span>
                    })
                    }

                    <Loader type="Rings" color="#00BFFF" height={80} width={80} visible={loadFollowing} />

                </div>
            </div>
        </div>
        <div className="col-lg-6" style={{ marginTop: 10 }}>
            <div className="card">
                <div
                    className="card-body"
                    style={{
                        boxShadow:
                            "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)"
                    }}
                >
                    <h4 className="card-title">Followers</h4>
                    <h6 className="text-muted card-subtitle mb-2">
                       Showing only the first 100 Followers
                    </h6>
                    {loadFollowers ? "" : followers.map((res: any, key: number) => {
                        return <span onClick={()=>history.push("/"+res.login)} key={"follower-"+key}><img
                            
                            className="rounded-circle"
                            src={res.avatar_url}
                            height="50px"
                            width="50px"
                            style={{ margin: 5 }}
                        /></span>
                    })
                    }

                    <Loader type="Rings" color="#00BFFF" height={80} width={80} visible={loadFollowers} />
                </div>
            </div>
        </div>
    </div>
</div>
);
}
export default Followers;