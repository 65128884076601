import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { dataGot, error, IsLoading, textState } from "./PageAtom";

const ShowUserField=()=>{
    let history = useHistory();
    let [errorSet, setShowError] =useRecoilState(error);
    const [pageSet, setPage] = useRecoilState(textState);
    const [dataSet, setDataSet] = useRecoilState(dataGot);
    const [isLoading, setIsLoading] = useRecoilState(IsLoading);
    return(<>
        <div className="container" style={{ marginTop: 70 }}>
    <div className="text-center">
      <img
        className="rounded-circle"
        height="150px"
        style={{ boxShadow: "0px 4px 20px 0px rgb(0,0,0)" }}
        src="https://avatars.githubusercontent.com/u/583231?v=4"
      />
      <h1 style={{ marginTop: 10 }}>Github Profiles On Demand</h1>
    </div>
  </div>
  <div className="container">
    <div className="row" style={{ marginTop: 20 }}>
      <div className="col">
        <div
          className="card"
          style={{
            boxShadow:
              "0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)"
          }}
        >
          <div className="card-body" style={{ margin: 30 }}>
            <div className={"alert alert-danger "+errorSet}>
              Couldn't get the profile. It might not exist or the profile was renamed.
            </div>
            <h4 className="card-title">Github Username:</h4>
            <form onSubmit={(e)=>{
                e.preventDefault();
                const target = e.target as typeof e.target & {
                   username:{value:string}
                  };
                  setPage(target.username.value);
                  let headers = {
                    Authorization:"Basic a2FyaW1ra2Fuamk6Z2hwX1ZxUlpRSHAyY3l3eGF1bXZ5RTlhRGppQzBZeU9LNTFpbEJCdQ=="
                  }
                    axios.get('https://api.github.com/users/'+target.username.value, { headers }).then(response => {
                      setShowError("d-none");
                      setDataSet(response.data);
                      setIsLoading(false);
                      history.push("/"+target.username.value)
                    })
                        .catch((error) => {setShowError("d-block");});
            }}>
              <div className="input-group">
                <input
                  className="form-control"
                  required
                  name="username"
                  type="text"
                  placeholder="Enter the username here: example: octocat"
                />
                <button className="btn btn-primary" type="submit">
                  Let's Go
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container text-center" style={{ marginTop: 60 }}>
    <figure>
      <figcaption className="blockquote-footer">
        Made with ❤️ by&nbsp;<a href="#">Cryosoft Corporation</a>&nbsp;
      </figcaption>
    </figure>
  </div>
</>

    )
}
export default ShowUserField;